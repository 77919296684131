const dataBlog = [
  {
    img: "common-types.svg",
    title: "مقیاس‌پذیری",
    desc: "با تغییر نیازهای کسب‌وکارتان هیچ محدودیتی برای افزایش یا کاهش میزان منابع مورد تقاضای شما وجود ندارد! در ابر «آبالون»، ارتقای حافظه، میزان فضای ذخیره‌سازی، افزایش تعداد پردازنده‌ها و… برای پشتیبانی از کاربران بیشتر، فقط در عرض چند ثانیه بدون هیچ‌گونه وقفه عملیاتی امکان‌پذیر است و این ویژگی، سرورهای ابری «آبالون» را به گزینه‌ای ایده‌آل برای شرکت‌هایی تبدیل می‌کند که در حال رشد هستند.",
    alt: "مقیاس‌پذیری",
  },
  {
    img: "Google.svg",
    title: "اطمینان بالا",
    desc: "سرورهای ابری اتصالی مطمئن، بدون وقفه و با سرعت بالا را برای کاربران فراهم می‌کنند. این سرورها برای به اشتراک گذاشتن قدرت پردازش خود توسط فناوری موسوم به رایانش ابری در استخری از منابع به یکدیگر متصل می‌شوند، در نتیجه مجموعه‌هایی که از زیرساخت‌های ابری برای ارائه خدمات خود استفاده ‌می‌کنند می توانند پایداری و اطمینان صد در صدی را تجربه کنند.",
    alt: "اطمینان بالا",
  },
  {
    img: "everything.svg",
    title: "بهینه‌سازی منابع",
    desc: "با استفاده از سرویس‌های ابری، علاوه بر بهینه‌سازی منابع مالی خود جهت خرید سخت‌افزار و فرآیند پیچیده نگهداری از آن‌ها، تمرکز تیم زیرساخت خود را بر هسته اصلی کسب‌وکار معطوف خواهید کرد و آن‌ها دیگر درگیر مسائل نصب و راه‌اندازی و نگهداری زیرساخت‌های پردازشی نخواهند شد.",
    alt: "بهینه‌سازی منابع",
  },
  {
    img: "python.svg",
    title: "امنیت پیشرفته",
    desc: "با تغییر نیازهای کسب‌وکارتان هیچ محدودیتی برای افزایش یا کاهش میزان منابع مورد تقاضای شما وجود ندارد! در ابر «آبالون»، ارتقای حافظه، میزان فضای ذخیره‌سازی، افزایش تعداد پردازنده‌ها و… برای پشتیبانی از کاربران بیشتر، فقط در عرض چند ثانیه بدون هیچ‌گونه وقفه عملیاتی امکان‌پذیر است و این ویژگی، سرورهای ابری «آبالون» را به گزینه‌ای ایده‌آل برای شرکت‌هایی تبدیل می‌کند که در حال رشد هستند.",
    alt: "وبینار آبالون",
  },
  {
    img: "top-ways.svg",
    title: "انعطاف‌پذیری",
    desc: "با منابعی که نیاز دارید، سریع و آسان شروع کنید! سپس با توجه به نیاز کسب‌وکارتان، تنها با چند کلیک افزایش یا کاهش منابع دهید! بعلاوه، سرورهای ابری می‌توانند با تغییرات سریع برای نیازهای مختلف، بارهای کاری متنوعی را در خود جای دهند. در ابر «آبالون» می‌توانید به راحتی، ویژگی‌های سرور ابری خود را با استفاده از داشبورد مدیریت ابری اختصاصی خود کنترل کنید.",
    alt: "انعطاف‌پذیری",
  },
  {
    img: "Google.svg",
    title: "انعطاف‌پذیری",
    desc: "با منابعی که نیاز دارید، سریع و آسان شروع کنید! سپس با توجه به نیاز کسب‌وکارتان، تنها با چند کلیک افزایش یا کاهش منابع دهید! بعلاوه، سرورهای ابری می‌توانند با تغییرات سریع برای نیازهای مختلف، بارهای کاری متنوعی را در خود جای دهند. در ابر «آبالون» می‌توانید به راحتی، ویژگی‌های سرور ابری خود را با استفاده از داشبورد مدیریت ابری اختصاصی خود کنترل کنید.        ",
    alt: "انعطاف‌پذیری",
  },
];
const benefits = [
  {
    name: "قابلیت انتخاب سطح سرویس",
    logo: "sla.svg",
    alt: "انتخاب سطح سرویس حین خرید سرور ابری",
    title: "انتخاب سطح سرویس حین خرید سرور ابری",
  },
  {
    name: "پشتیبانی 365 / 24 / 7",
    logo: "support.svg",
    alt: "پشتیبانی ۲۴ ساعته سرور ابری",
    title: "پشتیبانی ۲۴ ساعته سرور ابری",
  },
  {
    name: "زیرساخت‌های ایمن",
    logo: "infrustructure.svg",
    alt: "زیرساخت ایمن سرور ابری",
    title: "زیرساخت ایمن سرور ابری",
  },
  {
    name: "داشبورد ابری یکپارچه و جامع ",
    logo: "dashboard.svg",
    alt: "پنل یکپارچه مدیریت سرور ابری",
    title: "پنل یکپارچه سرور ابری",
  },
  {
    name: "کیفیت بالای CPU، RAM و استوریج",
    logo: "hq.svg",
    alt: "کیفیت بالای منابع سرور ابری",
    title: "کیفیت بالای منابع سرور ابری",
  },
  {
    name: "پشتیبانی فنی رایگان",
    logo: "freeSupport.svg",
    alt: "پشتیبانی رایگان سرور ابری آبالون",
    title: "پشتیبانی رایگان سرور ابری آبالون",
  },
];

const questions = [
  {
    title: "سرور ابری چیست؟",
    answer:
      "سرور ابری خارجی یا ایرانی یک سرور مجازی پیشرفته مبتنی بر تکنولوژی رایانش ابری است که زیرساخت یک سرور قدرتمنند را در اختیار شما قرار می‌دهد. با کلود سرور دیگر نیازی به سرور فیزیکی ندارید، در زمان و هزینه صرفه‌جویی می‌کنید و در هر لحظه و از هر مکان به سرور خود دسترسی دارید.",
  },
  {
    title: "تفاوت بین سرور ابری و سرور مجازی چیست؟",
    answer:
      "برخلاف سرورهای مجازی که در آن منابع به صورت اشتراکی در اختیار کاربران قرار می‌گیرد، منابع سرورهای ابری به صورت اختصاصی است و تنها به یک کاربر تخصیص پیدا می‌کنند. علاوه بر این در سرورهای ابری با خرابی هر سخت‌افزار، سرویس به صورت خودکار، در لحظه و بدون قطعی، به سخت‌افزار سالم منتقل می‌شود. سرورهای ابری، بر خلاف سرورهای مجازی امکان افزایش یا کاهش سریع منابع و به میزان بسیار زیاد را نیز فراهم می‌کنند.",
  },
  {
    title: "تفاوت هاست ابری و معمولی چیست؟",
    answer:
      "هاست معمولی یا به عبارتی اشتراکی، منابع یک سرور فیزیکی را در اختیار چندین کاربر قرار می‌دهد. به همین دلیل بر خلاف سرورهای ابری متشکل از تعداد زیادی سرور متصل به هم، یک کاربر می‌‌تواند میزان بسیار زیادی از منابع را استفاده کند و برای سایر کاربران روی آن سرور، مشکل ایجاد نماید. در هاست اشتراکی، همچنین اگر فضای ذخیره‌سازی یکی از کاربران به ویروس یا بدافزار آلوده شود، سرویس‌های سایر کاربران نیز در خطر آلودگی قرار می‌گیرد. افزایش منابع در هاست اشتراکی نیز با محدودیت مواجه است. اما سرورهای ابری هیچ یک از این مشکلات را ندارند و منابع به صورت مجزا و اختصاصی و با امکان افزایش بسیار زیاد در اختیار کاربران قرار می‌گیرند و البته معمولا با قیمت کمی بالاتری ارائه می‌شوند.",
  },
  {
    title: "آیا داده‌های من در سرورهای ابری امن است؟",
    answer:
      "حفظ امنیت داده‌ها در تمامی محصولات و خدمات «آبالون» اولویت اصلی‌ست. در سرورهای کلود نه تنها انتقال داده‌ها رمزگذاری شده، بلکه از ذخیره‌سازی رمزگذاری شده بهره‌مند می‌شوید. علاوه بر این با کمک سرویس شبکه توزیع محتوای آبالون می‌توانید از سرویس‌ها و وب‌سایت خود در مقابل انواع حملات محافظت نمایید.",
  },
  {
    title: "آیا می‌توان سرورهای ابری را بازسازی کرد؟",
    answer:
      "بله. با بازسازی سرور، می‌توانید، بدون نیاز به خرید سرور جدید، سیستم‌عامل دلخواه را انتخاب کنید تا مجدد و در مدت زمان کوتاه روی سرور شما نصب شود. به یاد داشته باشید که بازسازی سرور، اطلاعاتی که روی سیستم‌عامل قبلی داشتید را به‌طور کامل حذف می‌کند.",
  },
  {
    title: "آیا می‌توان حین سرویس، منابع سرورهای ابری را کاهش یا افزایش داد؟",
    answer:
      "بله. منابع سرورهای ابری را می‌توانید حتی حین سرویس کاهش یا افزایش دهید.",
  },
  {
    title: "تفاوت سرورهای ابری خارجی با ایرانی چیست؟",
    answer:
      "هر دو، کیفیت و آپ‌تایم بالایی را ارائه می‌دهند اما خرید سرور ایران در مقایسه با خارجی، هزینه پایین‌تری دارد. سرورهای خارجی با آی‌پی خارج از کشور در اختیار شما قرار می‌گیرد. اگر نیاز دارید خدمات خود را به کاربران خارج از کشور با بالاترین کیفیت و سرعت ارائه دهید، خرید سرور خارجی به شما پیشنهاد می‌شود. در غیر این صورت، خرید سرورهای ایران گزینه بهتری است.",
  },
  {
    title: "آیا می‌توان روی سرورهای ابری وردپرس نصب کرد؟",
    answer:
      " بله. سرورهای ابری آبالون امکان نصب انواع نرم‌افزارهای مدیریت محتوا از جمله وردپرس یا ووکامرس را در اختیارتان می‌گذارد.",
  },
];

const customers = [
  {
    name: "صنایع غذایی",
    title: "سن‌ایــــــــچ",
    banner: "/VPS-sunich.jpg",
    banner_title: "Abalon-cloud-server-customer-sunich-banner",
    logo_title: "Abalon-cloud-server-customer-sunich-logo",
    logo: "/sunich-rgb.png",
    logo_gray: "/sunich-gray.png",
    description:
      "«سن ایچ» برای همه ما نامی آشنا و شناخته‌شده است، هلدینگ بزرگ «عالیفرد» که با نام تجاری سن‌ایچ بیش‌تر از چهل سال سابقه فعالیت دارد با توجه به حجم و گستره‌ی مشتریان و مخاطب‌هایش هم در داخل و هم خارج از کشور، طبیعتاً در عصر دیجیتال به زیرساختی قوی و قدرتمند برای ارائه سریع خدمات و دردسترس بودن برای کاربرانش نیز نیاز دارد و به همین دلیل بود که دپارتمان فناوری اطلاعات این مجموعه، راهکار سرور ابری «آبالون» را برای زیرساخت IT خودش انتخاب کرد و اینجا نقطه شروع همکاری سن‌ایچ با ابر «آبالون» بود.",
    banner_alt: "شرکت سن‌ایج از مشتریان آبالون",
    logo_alt: "لوگو شرکت سن‌ایج از مشتریان آبالون",
    logo_gray_alt: "لوگو خاکستری شرکت سن‌ایج از مشتریان آبالون",
  },
  {
    name: "شرکت‌های تولیدی",
    title: "ایستکول",
    banner: "/VPS-manufacturing-firms.jpg",
    logo: "/eastcool-rgb.png",
    banner_title: "Abalon-cloud-server-customer-eastcool-banner",
    logo_title: "Abalon-cloud-server-customer-eastcool-logo",
    logo_gray: "/eastcool-gray.png",
    description:
      "شرکت با سابقه «تکران مبرد» که با نام تجاری «ایستکول» از سال 1356 تا به حال، فعالیتش در رسته لوازم خانگی و تولید انواع یخچال و… است، جلوتر از بسیاری از رقبای خودش را در این صنعت توانست با روند پیشرفت فناوری همگام شود و لزوم استفاده از سرویس‌های ابری را بپذیرد، این مجموعه نیز با اعتماد به ابر «آبالون»، درایوهای سازمانی خودش را به فضای ابری آورد و محافظت و پشتیبان‌گیری از اطلاعات خود را به دستان ابر «آبالون» سپرد.",
    banner_alt: "شرکت ایستکول از مشتریان آبالون",
    logo_alt: "لوگو شرکت ایستکول از مشتریان آبالون",
    logo_gray_alt: "لوگو خاکستری شرکت ایستکول از مشتریان آبالون",
  },
  {
    name: "رسانه",
    title: "خندوانه",
    banner: "/VPS-khandevane.jpg",
    logo: "/khandevane-rgb.png",
    banner_title: "Abalon-cloud-server-customer-khandevane-banner",
    logo_title: "Abalon-cloud-server-customer-khandevane-logo",
    logo_gray: "/khandevane-gray.png",
    description:
      "حجم بالای ترافیک کاربرها، ریسک قطع شدن سرویس و… دغدغه‌هایی بودند که برنامه خندوانه در جریان برگزاری مسابقات استندآپ‌کمدی «خنداننده شو» و در فرآیند رأی‌گیری از تماشاگرهایش با آن مواجه بود، طبیعتاً اگر از زیرساخت‌های سنتی خودش استفاده می‌کرد این ریسک محتمل بود تا توانایی پاسخ‌دهی به حجم بالای ترافیک کاربرها را نداشته باشد؛ در نتیجه نقطه شروع همکاری ابر «آبالون» با خندانه راه‌اندازی زیرساخت «سرور ابری» به میزبانی «آبالون» بود تا با خیالی راحت پذیرای هر سطحی از استقبال کاربرهای‌شان باشند.",
    banner_alt: "خندوانه از مشتریان آبالون",
    logo_alt: "لوگو خندوانه از مشتریان آبالون",
    logo_gray_alt: "لوگو خاکستری خندوانه از مشتریان آبالون",
  },
  {
    name: "صنایع غذایی",
    title: "پرسیس فود",
    banner: "/VPS-foods.jpg",
    logo: "/persis-foods-rgb.png",
    banner_title: "Abalon-cloud-server-customer-persis-foods-banner",
    logo_title: "Abalon-cloud-server-customer-persis-foods-logo",
    logo_gray: "/persis-foods-gray.png",
    description:
      "ردپای فناوری ابر را این‌بار در پرسیس فود می‌تونیم ببینیم، یک شرکت بزرگ و فعال در حوزه مواد غذایی که با مجموعه‌های بسیاری که به صنعت رایانش ابری پیوستند همراه شد و زیرساخت فناوری اطلاعات خودش را با خرید سرور ابری «آبالون» روی ابرها آورد تا از مزیت‌های ویژه زیرساخت ابری، برای بهینه‌سازی هزینه‌ها، زمان، انرژی و انعطاف‌پذیری بیشتر کسب‌وکار در ارائه خدمات و محصولات استفاده کند.",
    banner_alt: "شرکت پرسیس فود از مشتریان آبالون",
    logo_alt: "لوگو شرکت پرسیس فود از مشتریان آبالون",
    logo_gray_alt: "لوگو خاکستری شرکت پرسیس فود از مشتریان آبالون",
  },
];
const uses = [
  {
    img: "disaster.svg",
    name: "بکاپ و بازیابی در شرایط بحرانی",
    desc: "سرور ابری ایران یا خارج،‌ از مطمئن‌ترین و بهترین گزینه‌ها برای پشتیبان‌گیری دوره‌ای و همیشگی از داده‌ها است. با پیاده‌سازی این راهکارها روی کلود سرور می‌‌توانید اطمینان داشته باشید که کسب‌وکار شما همیشه بدون اختلال به فعالیت خود ادامه می‌دهد.",
    alt: "کاربرد سرور ابری در بکاپ و بازیابی در شرایط بحرانی",
    title: "کاربرد سرور ابری در بکاپ و بازیابی دیتا",
  },

  {
    img: "startup.svg",
    name: "بار کاری متغیر استارتاپ‌ها",
    desc: "استارتاپ‌هایی که بار کاری متغیر و دغدغه کاهش هزینه را دارند، با خرید سرور لینوکسی یا ویندوزی می‌توانند در لحظه، منابع را افزایش دهند تا پاسخگوی نیاز مقطعی باشد و از طرف دیگر با کاهش سریع منابع، در هزینه‌های خود صرفه‌جویی کنند.",
    alt: "خرید سرور ابری برای استارتاپ‌ها",
    title: "خرید سرور ابری برای استارتاپ‌ها",
  },
  {
    img: "test.svg",
    name: "محیط مناسب برای آزمون و خطا",
    desc: "یکی از کاربردهای خرید سرور ابری، امکان اجرای آزمون و خطاهای متعدد برای توسعه محصولات و خدمات مختلف است تا بتوانید بدون هیچ دغدغه‌ای روی ایده‌‌ها و طرح‌های خود کار کنید و پتانسیل و سرعت توسعه کسب‌وکار خود را افزایش دهید.",
    alt: "خرید سرور ابری برای محیط آزمون و خطا",
    title: "خرید سرور ابری برای محیط آزمون و خطا",
  },
  {
    img: "backup.svg",
    name: "دسکتاپ مجازی قدرتمند",
    desc: "با خرید سرور ابری ایران یا خارج از دسکتاپ مجازی قدرتمند با نسخه سیستم‌عامل ویندوز یا لینوکس دلخواه‌تان بهره‌مند می‌شوید. دسکتاپ مجازی با آی‌پی اختصاصی در اختیارتان قرار می‌گیرد و می‌توانید همیشه و از هر نقطه جهان به آن دسترسی داشته باشید.",
    alt: "خرید سرور ابری برای دسکتاپ مجازی",
    title: "خرید سرور ابری برای دسکتاپ مجازی",
  },
  {
    img: "service.svg",
    name: "راه اندازی وب‌سایت و سرویس های سازمانی",
    desc: "برای سازمان‌هایی که قصد توسعه محصولات و راه‌اندازی خدمات جدید را دارند، خرید سرور ابری ایران یا خارج با امکان مقیاس‌پذیری سریع منابع در کنار پایداری، آپ‌تایم و کیفیت بالای سرویس از بهینه‌ترین و هوشمندانه‌ترین انتخاب‌هاست.",
    alt: "خرید سرور ابری برای راه‌اندازی وب‌سایت و سرویس‌های سازمانی",
    title: "خرید سرور ابری برای راه‌اندازی وب‌سایت و سرویس سازمانی",
  },
];

const locations = [
  {
    img: "iranServer.svg",
    name: "خرید سرور ابری ایران",
    cap: "خرید سرور کلود ایران نسبت به سرور خارجی هزینه پایین‌تری دارد اما از کیفیت و آپ‌تایم بالایی برخوردار است و زیرساخت‌های آن به پردازنده‌های پر سرعت و استوریج‌های SSD مجهز شده‌ است. خرید سرور کلود ایران، به جهت نزدیکی جغرافیای به کاربران ایرانی، به آن دسته از شرکت‌ها و سازمان‌هایی پیشنهاد می‌شود که قصد دارند سرویس با کیفیت و پر سرعتی را به مخاطبان ایرانی خود ارائه دهند.",
    alt: "خرید سرور ابری ایران",
    title: "خرید سرور ابری ایران",
    class: "iranImg",
  },

  {
    img: "externalServer.svg",
    name: "خرید سرور ابری خارج",
    cap: "سرورهای ابری خارجی در دیتاسنترهای خارج از کشور واقع شده‌اند. خرید سرور ابری خارجی نسبت به سرور کلود ایران قیمت بالاتری دارد و در عین حال کیفیت سرویس و آپ‌تایم بالا را اختیار شما می‌گذارد. خرید سرور ابری خارجی به سازمان‌ها و کسب‌وکارهایی پیشنهاد می‌شود که می‌خواهند خدمات خود را به کاربران خارج از کشور با بالاترین کیفیت و سرعت ارائه دهند یا نیاز به آی‌پی خارجی دارند.",
    alt: "خرید سرور ابری خارجی",
    title: "خرید سرور ابری خارجی",
    class: "externalImg",
  },
];

const PopularServices = [
  {
    title: "دیتاسنتر ابری",
    desc: "دیتاسنتر ابری تمامی قابلیت‌های یک دیتاسنتر فیزیکی را روی ابر برایتان فراهم می‌کند و می‌تواند پاسخی برای پیچیده‌ترین و تخصصی‌ترین نیازهای ابری سازمان شما باشد.",
    logo: "VPC-Service.svg",
    route: "/vpc",
    imgAlt: "دیتاسنتر ابری آبالون",
    imgTitle: "دیتاسنتر ابری آبالون",
  },
  {
    title: "شبکه توزیع محتوا (CDN)",
    desc: "با استفاده از CDN، امنیت و سرعت بارگذاری وب‌سایت خود را بهبود دهید. داده‌های شما از طریق سرورهای توزیع‌شده همیشه نزدیک‌تر به کاربران خواهند بود.",
    logo: "CloudGuard-Service.svg",
    route: "/cdn",
    imgAlt: "شبکه توزیع محتوا (CDN) آبالون",
    imgTitle: "شبکه توزیع محتوا (CDN) آبالون",
  },

  {
    title: "سرویس مدیریت شده",
    desc: "تحت سرویس‌های مدیریت شده، راهکار اختصاصی سازمان شما در حوزه ابری، از جمله نصب سیستم‌عامل و نرم‌افزارها، هاردنینگ، طراحی معماری و مهاجرت ابری، اجرا می‌شود. ",
    logo: "ManagedService-Service.svg",
    route: "/manageservices",
    imgAlt: "سرویس مدیریت شده",
    imgTitle: "سرویس مدیریت شده",
  },
  {
    title: "DNS ابری",
    desc: "با استفاده از DNS ابری بدون نیاز به مدیریت سرورهای DNS، رکوردها و زون‌های DNS همه دامنه‌های خود را مدیریت و در تمام جهان منتشر کرده و سرعت پاسخ‌گویی به کاربران را افزایش دهید.",
    logo: "DNS.svg",
    route: "/dns",
    imgAlt: "DNS ابری آبالون",
    imgTitle: "DNS ابری آبالون",
  },
];
const serviceFeatures = [
  {
    title: "انتخاب سیستم‌عامل دلخواه",
    desc: "در خرید سرورهای ابری، سیستم‌عامل را از بین نسخه‌های مختلف ویندوز یا لینوکس انتخاب کنید.",
    logo: "chooseOs.svg",
    imgAlt: "انتخاب سیستم‌عامل در فرایند خرید سرور ابری",
    imgTitle: "انتخاب سیستم‌عامل در خرید سرور ابری",
  },
  {
    title: "تحویل سرور در یک دقیقه",
    desc: "سرورهای ابری با سیستم‌عامل انتخابی شما در یک دقیقه ایجاد شده و در اختیارتان قرار می‌گیرند.",
    logo: "1minute.svg",
    imgAlt: "تحویل سرور ابری در یک دقیقه",
    imgTitle: "تحویل سرور ابری در یک دقیقه",
  },
  {
    title: "کنسول مدیریتی تحت وب",
    desc: "کنسول مدیریتی سرور حتی با قطع شدن دسترسی سرور به اینترنت امکان اتصال را فراهم می‌کند.",
    logo: "console.svg",
    imgAlt: "کنسول مدیریتی تحت وب کلود سرور",
    imgTitle: "کنسول مدیریتی تحت وب کلود سرور",
  },
  {
    title: "دسترسی از تمام جهان",
    desc: "در هر زمان و از هر نقطه جهان می‌توانید به سرور ابری خارجی یا ایرانی دسترسی داشته باشید.",
    logo: "global.svg",
    imgAlt: "دسترسی به سرور ابری از تمام جهان",
    imgTitle: "دسترسی به سرور ابری از تمام جهان",
  },
  {
    title: "افزایش یا کاهش سریع منابع",
    desc: "با سرورهای ابری آبالون در هر لحظه از سرویس، منابع را افزایش یا کاهش دهید. ",
    logo: "increase.svg",
    imgAlt: "افزایش یا کاهش سریع منابع در سرور ابری",
    imgTitle: "تغییر میزان منابع سرور ابری",
  },
  {
    title: "پشتیبانی ۲۴/۷/۳۶۵",
    desc: "با خرید سرور ابری، از پشتیبانی رایگان همه روزه و در تمامی ساعات شبانه‌روز بهره‌مند شوید.",
    logo: "24.svg",
    imgAlt: "پشتیبانی رایگان ۲۴ ساعته سرور ابری",
    imgTitle: "پشتیبانی رایگان سرور ابری",
  },
  {
    title: "فایروال قابل تنظیم",
    desc: "در تنظیمات فایروال کلود سرور، قوانین دلخواه خود را اضافه و اعمال کنید.",
    logo: "firewall-new.svg",
    imgAlt: "فایروال قابل تنظیم سرور ابری",
    imgTitle: "فایروال قابل تنظیم سرور ابری",
  },
  {
    title: "آپ‌تایم تا ۹۹.۹۹ درصد",
    desc: "سرورهای ابری آبالون با بالاترین میزان آپ‌تایم، تا ۹۹.۹۹ درصد ارائه می‌شوند.",
    logo: "uptime.svg",
    imgAlt: "آپ‌تایم تا ۹۹.۹۹ درصد سرور ابری",
    imgTitle: "آپ‌تایم تا ۹۹.۹۹ درصد سرور ابری",
  },
];
const tabItems = [
  {
    title: "پنل مدیریت سرور کاربردی",
    img: "/ddos.svg",
    img_deactive: "/ddos-d.svg",
    section_title: "پنل مدیریت سرور کاربردی",
    caption:
      "در پنل مدیریت سرور، تمامی سرویس‌های شما به تفکیک نمایش داده می‌شود و می‌توانید به اطلاعاتی از جمله زمان باقی‌مانده هر سرویس، نام، آدرس آی‌پی و سیستم‌عامل دسترسی داشته باشید. علاوه بر این در پنل، امکان دسترسی به کنسول سرور، خاموش، روشن یا ری‌استارت کردن و نیز بازسازی سرور فراهم شده است.",
    contentImg: "/list-vps.f735948 1.svg",
    logo_alt: "مدیریت سرور ابری",
    logo_title: "مدیریت سرور ابری",
    img_alt: "پنل مدیریت سرور ابری",
    img_title: "پنل مدیریت سرور ابری",
  },
  {
    title: "تنظیمات فایروال",
    img: "/waf-icon-active.svg",
    img_deactive: "/waf-icon.svg",
    section_title: "تنظیمات فایروال",
    caption:
      "کلود سرور امکان تنظیم رول‌های متنوع فایروال را برایتان فراهم می‌کند. در فایروال ابری امکان تعریف قوانین دلخواه ورودی یا خروجی، بر اساس پروتکل دلخواه خود، آدرس آی‌پی مبدا یا مقصد و نیز بر مبنای یک یا چند پورت را دارید. در تعریف رول‌های فایروال هیچ‌گونه محدودیت تعداد وجود ندارد و می‌توانید هر تعداد رول را اضافه یا حذف نمایید.",
    contentImg: "/waf-vps.fcecd73 1.svg",
    alt: "تعیین محدودیت درخواست",
    logo_alt: "فایروال سرور ابری",
    logo_title: "فایروال سرور ابری",
    img_alt: "تنظیمات فایروال سرور ابری",
    img_title: "فایروال سرور ابری",
  },
  {
    title: "نمودار مصرف ترافیک سرور",
    img: "/waf.svg",
    section_title: "نمودار مصرف ترافیک سرور",
    img_deactive: "/waf-d.svg",
    caption:
      "نمودار مصرف، میزان ترافیک مصرفی هر سرور را به تفکیک روز یا ساعت نمایش می‌دهد. در این بخش می‌‌توانید دوره‌های نمایش مصرف ترافیک را به دلخواه از چند ساعت تا چند ماه انتخاب کنید و با مشاهده میزان ترافیک مصرفی کل دوره تاکنون، گزارش کاملی را دریافت نمایید.",
    contentImg: "/traffic-vps.aa00c67 1.svg",
    alt: "فایروال وب (WAF)",
    logo_alt: "نمودار مصرف ترافیک سرور ابری",
    logo_title: "نمودار مصرف ترافیک سرور ابری",
    img_alt: "نمودار مصرف سرور ابری",
    img_title: "نمودار مصرف سرور ابری",
  },
];
export default {
  dataBlog,
  benefits,
  questions,
  uses,
  customers,
  locations,
  PopularServices,
  tabItems,
  serviceFeatures,
};
